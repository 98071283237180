.breadcrumbs {
  margin-bottom: 0;
  padding-bottom: 20px;
  font-weight: bold;

  li:not(:last-child)::after {
    margin: 0;
    margin-right: 2px;
    color: $black4;
  }

  a, li {
    color: $black4;
    font-size: 14px;
  }

  &-block {
    margin-top: 60px;

    @include breakpoint(medium) {
      margin-top: 150px;
    }
  }
}