// --------------------------------------------------------
//
// 每個頁面引入的檔案
//
// --------------------------------------------------------
// 說明 (完整說明見) src/sass/all.scss
//	* 此檔案包含：通用於所有SCSS/SASS變數檔案
//      - 變數、mixin、function
//      - 所有單元都建議須要載入之樣式
//      - 避免寫實際的樣式於該檔案內（包含檔案內 @import 之其它CSS檔案）

@charset 'utf-8';

// --------------------------------------------------------
// Vendor

// Framework 相關設定
@import '../foundation/foundation';

// Icon Font
@import '../vendor/font/icons'; // 載入Icon Font 變數

// Function
@import '../vendor/trig'; // 三角函數
@import '../vendor/photoshop-function'; // Photoshop 效果轉換

// --------------------------------------------------------
// Variable
// --------------------------------------------------------

$primary-color: #222222; // 主顏色變數

$white: #fff;
$black: #040000;
$black2: #333;
$black3: #E5E5E5;
$black4: #4d4d4d;

$gray1: #D9D9D9;

// Txt
$main-txt: #000;
$main-link-color: #040000;

//gradient
$gr-or: #D98A4B;
$gr-pi: #C73A60;
$gr-pu: #903478;
$gr-bl: #009CC8;
$gr-gr: #259156;
$gr-ye: #F6E33E;


// font-family
$font-family-base: 'Roboto', 'PingFang TC', 'Noto Sans TC', 'Microsoft JhengHei', 'sans-serif';

// padding
$sm-padding: 24px;
$md-padding: 80px;
$lg-padding: 120px;

// --------------------------------------------------------
// Function
// --------------------------------------------------------

// --------------------------------------------------------
// Mixin
// --------------------------------------------------------

// ---------------------------------------- [START] Gradient
@mixin gradient($deg: -45deg) {
	background: linear-gradient($deg,rgba($gr-or,1),rgba($gr-pi,1),rgba($gr-pu,1));
}

@mixin gradient2($deg: -45deg) {
	background: linear-gradient($deg, rgba($gr-pi,1) 0%, rgba($gr-pu,1) 30%, rgba($gr-bl,1) 230% );
}
// ---------------------------------------- [END] Gradient

// ---------------------------------------- [START] Flex
@mixin fc($justify, $align) {
	display: flex;

	@if $justify {
		justify-content: $justify;
	}

	@if $align {
		align-items: $align;
	}
}
// ---------------------------------------- [END] Flex

// ---------------------------------------- [START] BEM Mixin
// 遵循BEM的規則，於class增加tag（僅限於單層方式，多層不適用）
// 可使用（單層）： .component__link => a.component__link
// 不適用（多層）： .component .component__link => .component a.component__link
@mixin bem-tag($tag: 'a') {
	$current: &;

	@at-root #{$tag}#{$current} {
		@content;
	}
}
// ---------------------------------------- [END] BEM Mixin

// ---------------------------------------- [START] before-img-size
// 使用 ::before 的 padding-top 撐高度
@mixin before-img-size($width: 1, $height: 1, $build-other: true) {
	@if $build-other {
		content: '';
		display: block;
	}

	padding-top: percentage($height / $width);
}
// ---------------------------------------- [END] before-img-size

// ---------------------------------------- [START] Media Query 相關
// Hover Media Query (使用 Foundation Mixin)
$project-has-ie: false; // 專案是否有含 IE => 有 IE 就不能使用 @media hover

@mixin breakpoint-hover($hover-enable: true) {
	// 有 IE 專案
	@if $project-has-ie {
		@if $hover-enable {
			@media screen and (min-width: 75rem) {
				@content;
			}
		} @else {
			@media screen and (max-width: 74.9375rem) {
				@content;
			}
		}
	}

	// 無 IE 專案
	@else {
		@if $hover-enable {
			@media (hover: hover) {
				@content;
			}
		} @else {
			@media (hover: none) {
				@content;
			}
		}
	}
}

// Breakpoint Between (使用 Foundation Mixin)
@mixin breakpoint-between($start, $end) {
	@media screen and (min-width: #{$start}px) and (max-width: #{$end}px) {
		@content;
	}
}
// ---------------------------------------- [END] Media Query 相關

// ---------------------------------------- [START] Custom Scroll Bar
$scrollbar-thumb-color: $primary-color; // 操作顏色
$scrollbar-track-color: #eee; // 滾軸背景
$scrollbar-width: 6px; // 寬度(瀏覽器預設為15px)
$scrollbar-border-radius: 6px; // 圓角，Firefox不支援

@mixin custom-scroll-bar($thumb-color: $scrollbar-thumb-color, $track-color: $scrollbar-track-color) {
	// For Chrome, Opera, Safari, Android, iOS
	&::-webkit-scrollbar {
		width: $scrollbar-width;
		height: $scrollbar-width;
	}

	&::-webkit-scrollbar-track {
		background: $track-color;
	}

	&::-webkit-scrollbar-thumb {
		background: $thumb-color;
		border-radius: $scrollbar-border-radius;

		&:hover {
			background: darken($thumb-color, 10%);
		}
	}

	@if & {
		// For Firefox (版本必須64+)
		// 如果是整頁<body>要使用，必須將Class增加於<html>標籤上
		// Ref: https://stackoverflow.com/a/6165489/11240898
		// 顏色：操作顏色(Thumb) 滾軸背景(Track)
		scrollbar-color: $thumb-color $track-color;
		// 寬度只有 auto | thin | none 選項
		// auto為15px，thin為6px，none為不顯示
		scrollbar-width: thin;
	}
}
// ---------------------------------------- [END] Custom Scroll Bar

// ---------------------------------------- [START] Animation Set
@mixin set-animate($animateEl) {
	@each $class in $animateEl {
		$i: index($animateEl, $class);
		$i: $i;

		#{$class} {
			opacity: 0;
			transform: translateY(50px);
			transition-duration: 1s;
			transition-property: opacity, transform;
			transition-delay: #{$i * 0.3s};
		}
	}
}

@mixin set-animated($animateEl) {
	@each $class in $animateEl {
		#{$class} {
			opacity: 1;
			transform: none;
		}
	}
}

@mixin set-animate-flow($animateEl) {
	&.js-ani {
		@include set-animate($animateEl);

		&.is-animated {
			@include set-animated($animateEl);
		}
	}
}

// ---------------------------------------- [END] Animation Set

// ---------------------------------------- [START] 內容寬度設定

@mixin content-padding() {
  padding-left: $sm-padding;
  padding-right: $sm-padding;
  box-sizing: content-box;

  @include breakpoint(medium) {
    padding-left: $md-padding;
    padding-right: $md-padding;
  }

  @include breakpoint(large) {
    padding-left: $lg-padding;
    padding-right: $lg-padding;
  }
}

// ---------------------------------------- [END] 內容寬度設定