// 頁碼
.pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 30px;
	margin-left: 0;

  .co-icon {
    transition: 0.36s;
  }

	.pagination-item {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		margin: 0 rem-calc(5);
		padding: 0;
    background: linear-gradient(-45deg, #009cc8, #259156, #f6e33e);

		&:not(.current):not(.previous):not(.next):not(.ellipsis) {
			.pagination-link {
				@include breakpoint(large) {
					&:hover {
            color: $white;
            background-color: transparent;
            border-color: transparent;
					}
				}
			}
		}
	}

	.previous,
	.next {
		margin: 0;
		min-width: auto;
		height: auto;

    .pagination-link {
      background-color: $black;
      color: $white;
    }

		@include breakpoint(large) {
			&:hover {
        .pagination-link {
          background-color: transparent;
          border-color: transparent;
        }
			}
		}
	}

	.previous {
		margin-right: rem-calc(5);
	}

	.next {
		margin-left: rem-calc(5);
	}

	.previous.pagination-item,
	.pagination-next.pagination-item {
		&::before,
		&::after {
			content: none !important;
		}
	}

	.pagination-link {
		&::before {
			content: none !important;
		}

		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0;
		color: $black;
		font-size: rem-calc(26);
		transition: 0.36s;
		line-height: 1;
    width: 40px;
    height: 40px;
    border: 2px solid $black;
    background-color: $white;
	}

	.disabled {
		pointer-events: none;

		a::before {
			content: none !important;
		}
	}

	.current {
		pointer-events: none;

		.pagination-link {
			color: $white;
      background-color: $black;

			&::after {
				width: 1rem;
			}
		}

		@include breakpoint(large) {
			font-size: rem-calc(18);
		}
	}

	.ellipsis::after {
		padding: 0;
	}

	.be-icon {
		line-height: 1;
	}
}
