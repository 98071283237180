// Form / Input / Select / Textarea
.field-label {
  &_txt {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $black;
    font-weight: 400;
  }

  &.is-error {
    .field-ctrl {
      border-color: $gr-pi;
    }
    .field-error {
      display: block;
    }
  }

  .require-icon {
    margin-left: 4px;
  }
}

.field-ctrl {
  margin-bottom: 0;
  border-color: transparent;
  box-shadow: none;

  &:focus {
    box-shadow: none;
    border-color: transparent;
    border-bottom-color: $black;
  }
}

// error
.field-error {
  display: none;
  margin-bottom: 0;
  margin-top: 8px;
  font-size: 14px;
  color: $gr-pi;
}


.form {
  &-cta {
    margin-top: 24px;
    padding-top: 24px;
  }

  &-btn {
    margin-top: 12px;
  }
}

.checkbox {
  &-input {
    display: grid;
    place-content: center;
    width: 24px;
    height: 24px;
    border: 2px solid $black;
    background-color: $white;
    appearance: none;
    margin-bottom: 0;
    overflow: hidden;
    flex-shrink: 0;

    &::before {
      content: "";
      width: 24px;
      height: 24px;
      transform: scale(0) rotate(30deg);
      transition: 0.36s all ease-in-out;
      box-shadow: inset 1em 1em var(--form-control-color);
      background-color: $black;
      opacity: 0;
    }

    &:checked::before {
      transform: scale(1);
      opacity: 1;
    }
  }

  &-label {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon {
      margin-left: 4px;
    }
  }
}