.rdzvbtn {
	width: 100%;
	display: flex;
	&.left {
		justify-content: flex-start;
	}
	&.ctr {
		justify-content: center;
	}
	&.right {
		justify-content: flex-end;
	}
	&-ctr {
		background: linear-gradient(-45deg, rgba($gr-bl, 1), rgba($gr-gr, 1), rgba($gr-ye, 1));
		color: $white;
		padding: rem-calc(5 65 7 15);
		position: relative;
		overflow: hidden;
		border: rem-calc(1) solid $black;
		transition: 0.2s;
		&:focus {
			color: $white;
		}
		&::before {
			content: '';
			width: 100%;
			height: 100%;
			background-color: $black;
			position: absolute;
			top: 0;
			right: 0;
			z-index: 1;
			transition: 0.6s;
		}
		p,
		.bc {
			z-index: 2;
		}
		p {
			margin: 0;
			font-size: rem-calc(20);
			color: $white;
			position: relative;
			transition: 0.2s;
			@include breakpoint(xlarge down) {
				font-size: rem-calc(16);
			}
		}
		.bc {
			position: absolute;
			svg {
				path {
					fill: $white;
				}
			}
		}
		&-cuber {
			right: 0;
			top: rem-calc(0);
			width: rem-calc(33);
		}
		&-cubec {
			right: rem-calc(45);
			top: rem-calc(8);
			transform: rotate(120deg);
			width: rem-calc(15);
			transition: 0.2s;
		}
	}
	&.theme-white{
		.rdzvbtn-ctr {
			background: linear-gradient(-45deg, rgba($gr-bl, 1), rgba($gr-gr, 1), rgba($gr-ye, 1));
			color: $black;
			&:focus {
				color: $white;
			}
			&::before {
				background-color: $white;
			}
			p {
				color: $black;
			}
			.bc {
				svg {
					path {
						fill: $black;
						transition: 0.2s;
					}
				}
			}
		}
	}
	@include breakpoint(large) {
		&:hover {
			.rdzvbtn-ctr {
				border: rem-calc(1) solid $white;
				transition: 0.6s;
				&::before {
					opacity: 0;
					transition: 0.6s;
				}
				.bc.rdzvbtn-ctr-cubec {
					right: rem-calc(28);
					top: rem-calc(16);
					transform: rotate(52deg);
					transition: 0.6s;
				}
				p {
					color: $white;
					transition: 0.6s;
				}
				.bc {
					svg {
						path {
							fill: $white;
							transition: 0.6s;
						}
					}
				}
			}
		}
	}
}

.small-btn {
	position: relative;
	display: inline-block;
	padding: 9px 20px;
	color: $white;
	flex-shrink: 0;
	font-weight: 500;
	background: linear-gradient(-45deg, rgba($gr-bl, 1), rgba($gr-gr, 1), rgba($gr-ye, 1));
  white-space: nowrap;

  @include breakpoint(medium) {
    padding: 12px 20px;
  }

	&::before {
		content: '';
		width: 100%;
		height: 100%;
		background-color: $black;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
		transition: 0.6s;
	}

  &.is-active {
    &::before {
      opacity: 0;
      transition: 0.6s;
    }
  }

  .text {
    position: relative;
    z-index: 2;
  }

	@include breakpoint(large) {
		&:hover {
      &::before {
        opacity: 0;
        transition: 0.6s;
      }
		}
	}
}
