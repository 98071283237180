html {
  color: red;
}
// Popup / Modal / 彈跳視窗
.modal {
	$root: &;
	position: fixed;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: block;
	width: 100%;
	height: 100%;
	opacity: 0;
	pointer-events: none;
	transition: 0.3s opacity, 0.3s z-index 0s;

	// open / close
	&.is-active {
		z-index: 9999;
		pointer-events: all;
		opacity: 1;
		transition: 0.3s opacity;

		.modal_inner {
			transform: translate(-50%, -50%) translateZ(0) scale(1);
		}
	}

	// Inner
	&_inner {
		position: absolute;
		z-index: 2;
		top: 50%;
		left: 50%;
		padding: rem-calc(30 20);
		width: calc(100% - 40px);
		max-width: rem-calc(1300);
		transform: translate(-50%, -50%) translateZ(0) scale(0.95);
		background-color: $white;
		transition: 0.3s transform;
    border: 2px solid $black;

		@include breakpoint(large) {
			padding: rem-calc(60 40);
		}

		// 尺寸
		&.sz-sm {
     		max-width: 630px;
		}

    &.sz-lsm {
      max-width: 690px;
    }

		&.sz-lg {
			@include breakpoint(medium) {
				width: calc(100% - 120px);
			}

			max-width: rem-calc(1076);
		}
	}

	// Cont
	&_title {
    font-weight: 500;
	}

	&_note {
    margin-top: 24px;
    color: $black4;
	}

	// btn
	&_btn-group {
    margin-top: 33px;
	}

  .small-btn {
    min-width: 140px;
  }

	// 內層的關閉 btn
	&_close {
		cursor: pointer;
		position: absolute;
		z-index: 2;
    top: 0;
		right: 0;
		width: rem-calc(30);
		height: rem-calc(30);
    background-color: $black;
    transform: translate(50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;

		@include breakpoint(large) {
      width: rem-calc(60);
      height: rem-calc(60);
		}

		.co-icon {
			display: flex;
			align-items: center;
			justify-content: center;
      font-size: rem-calc(16);
      color: $white;

      @include breakpoint(large) {
        font-size: rem-calc(30);
      }
		}
	}

	// Overlay
	&_overlay {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($gray1, 0.95);
	}

	.scroll-box {
		@include custom-scroll-bar;
		overflow-y: auto;
	}
}