// --------------------------------------------------------
//
// Layout
//
// --------------------------------------------------------
// 說明 (完整說明見) src/all/all.scss
//	* 此檔案包含：Layout樣式 Loader、Header、Footer

// -------------------------------------------------------

/* Loader */
@keyframes loadsym {
  0%{transform: translate(rem-calc(5),rem-calc(5)) rotate(0deg) scale(0.5); opacity: 0;}
  100%{transform: translate(rem-calc(0),rem-calc(0)) rotate(0deg) scale(1); opacity: 1;}
}
@keyframes loadtext {
  0%{transform: scale(0.5); opacity: 0;}
  100%{transform: scale(1); opacity: 1;}
}
@keyframes loadpic {
  0%{transform: translate(rem-calc(-10),rem-calc(-10)) rotate(-6deg) scale(0.5); opacity: 0;}
  100%{transform: translate(rem-calc(0),rem-calc(0)) rotate(0deg) scale(1); opacity: 1;}
}
.loader {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  // background: $light-blue-1 url(../images/loading.svg) no-repeat center center;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  &_img {
    width: rem-calc(120);
    height: rem-calc(120);
    background: url(../images/hd_logo-symbol.svg) no-repeat center center;
    background-size: 100% 100%;
    pointer-events: none;
    position: relative;
    animation: loadsym 0.3s linear forwards;
    @include breakpoint(medium down) {
      width: rem-calc(60);
      height: rem-calc(60);
    }
    &::after,&::before{
      content: '';
      width: 100%;
      height: 100%;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      background-size: rem-calc(120) auto;
    }
    &::after{
      transform: translate(rem-calc(-10),rem-calc(-10)) rotate(-6deg) scale(0.5);
      background: url(../images/hd_logo-pic.svg) no-repeat center center;
      animation: loadpic 0.3s 0.2s linear forwards;
    }
    &::before{
      background: url(../images/hd_logo-text.svg) no-repeat center center;
      animation: loadtext 0.3s 0.4s linear forwards;
    }
  }

  // Loaded
  &.loaded {
    transform: scale(1);
    opacity: 0;
    transition: transform 0.6s, opacity 1s 0.4s;
    .loader_img {
      transition: transform 1s, opacity 1s 0s;
    }
  }
}
// -------------------------------------------------------

/* Header */
.hd {
  position: relative;
  z-index: 1000;

  .menu {
    width: rem-calc(85);
    height: rem-calc(85);
    background-color: $black;
    background: linear-gradient(-45deg,rgba($gr-or,1),rgba($gr-pi,1),rgba($gr-pu,1));
    position: fixed;
    z-index: 5;
    top: rem-calc(30);
    right: rem-calc(30);
    transition: 0.4s;
    cursor: pointer;

    @include breakpoint(xlarge only, large only) {
      transform: scale(0.7) translate(rem-calc(20), rem-calc(-20));
    }
    @include breakpoint(medium only) {
      transform: scale(0.6) translate(rem-calc(35), rem-calc(-35));
    }
    @include breakpoint(small down) {
      transform: scale(0.5) translate(rem-calc(65), rem-calc(-65));
    }
    &::before{
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background-color: $black;
      z-index: 1;
      opacity: 1;
      transition: 0.4s;
    }
    &-line {
      width: rem-calc(38);
      height: rem-calc(7);
      transform: rotate(-15deg);
      background-color: $white;
      transition: 0.4s;
      position: absolute;
      left: rem-calc(23.5);
      z-index: 9;
      &.top {
        top: rem-calc(22);
      }
      &.ctr {
        top: rem-calc(37);
      }
      &.btm {
        top: rem-calc(53);
      }
    }
    @include breakpoint(large) {
      &:hover{
        &::before{
          opacity: 0;
          transition: 0.4s;
        }
        .menu-line {
          transform: rotate(0deg);
          transition: 0.4s;
        }
      }
    }
  }

  &-logo {
    position: fixed;
    z-index: 5;
    top: rem-calc(30);
    right: rem-calc(135);
    width: rem-calc(85);
    height: rem-calc(85);
    background: url(../images/hd_logo-symbol.svg) no-repeat center center;
    background-size: 100% 100%;

    @include breakpoint(xlarge only, large only) {
      transform: scale(0.7) translate(rem-calc(60), rem-calc(-20));
    }
    @include breakpoint(medium only) {
      transform: scale(0.7) translate(rem-calc(80), rem-calc(-30));
    }
    @include breakpoint(small down) {
      transform: scale(0.5) translate(rem-calc(165), rem-calc(-65));
    }
    &::after,&::before{
      content: '';
      width: 100%;
      height: 100%;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      background-size: rem-calc(120) auto;
    }
    &::after{
      background: url(../images/hd_logo-pic.svg) no-repeat center center;
      transition: 0.4s;
    }
    &::before{
      background: url(../images/hd_logo-text.svg) no-repeat center center;
    }
    @include breakpoint(large) {
      &:hover{
        &::after{
          transform: translate(rem-calc(-5),rem-calc(3)) rotate(3deg);
          transition: 0.4s;
        }
      }
    }
  }

  &-collapse {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($white, 0.7);
    overflow: hidden;

    &-bg {
      position: absolute;
      height: 100%;
      width: 100%;
      left: -30%;
      top: calc(300/375*100vw);

      @include breakpoint(medium) {
        top: calc(250/375*100vw);
      }

      @include breakpoint(large) {
        width: 1400px;
        top: 0;
        right: 0;
        left: auto;
        height: percentage(1552/1080);
      }

      @include breakpoint(xlarge) {
        width: 1552px;
      }

      @include breakpoint(1920px) {
        width: percentage(1552/1920);
      }
    }

    .bevel-bg {
      pointer-events: none;
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;

      @include breakpoint(large) {
        align-items: center;
      }

      &_top {
        transform: rotate(-35deg);
        @include breakpoint(large) {
          transform: rotate(-52deg);
        }

        &::before {
          transform: translate(-25%);
          height: 300vw;
          width: 300vh;
          background-image: url('../images/bg-pic_b.jpg');
          background-size: 200px 200px;
          background-repeat: repeat;
        }
      }

      .pagetext {
        max-width: 100%;
        aspect-ratio: 1245 / 180;

        @include breakpoint(medium down) {
          max-width: 80%;
          width: 115%;
        }

        @include breakpoint(414px down) {
          max-width: 90%;
        }

        &-full {
          position: relative;
          right: -29%;
        }

        &-line {
          right: -30%;

					@include breakpoint(small down) {
						right: -29%;
					}

					@include breakpoint(414px down) {
						right: -27%;
					}
        }
      }

      &::after {
        content: none;
        background: none;
      }
    }
  }

  &-social {
    position: absolute;
    z-index: 5;
    top: 192px;
    right: 18px;
    color: $white;
    display: flex;
    flex-direction: column;
    align-items: baseline;

    @include breakpoint(medium) {
      right: 26px;
    }

    @include breakpoint(large) {
      right: 35px;
      top: 150px;
    }

    @include breakpoint(xxlarge) {
      right: 45px;
    }

    @media screen and (min-width: 1024px) and (min-height: 1200px) {
      top: 270px;
    }

    .text {
      position: relative;
      margin-bottom: 10px;
      padding-bottom: 30px;
      padding-left: 6px;
      writing-mode: vertical-rl;
      letter-spacing: 1.5px;
      font-size: 12px;
      @include breakpoint(large) {
        font-size: 14px;
        margin-bottom: 30px;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        width: 1px;
        height: 100%;
        background: $white;
      }
    }

    .share-list {
      margin: 0;
      display: flex;
      margin-right: 7px;
      flex-direction: column;

      @include breakpoint(medium) {
        margin-right: 0px;
      }

      @include breakpoint(large) {
        margin-right: 3px;
      }
    }
  }

  &-list {
    --nav-max-height: calc(100vh - 360px);

    position: absolute;
    z-index: 5;
    display: grid;
    gap: 20px;
    margin: 0;
    top: 300px;
    right: 80px;
    list-style: none;
    color: $white;
    text-align: right;
    max-height: var(--nav-max-height);
		height: 100%;
		// max-width: 336px;

    // @media screen and (min-width: 640px) and (min-height: 768px) {
    //   --nav-max-height: 540px;
    // }

    @media screen and (min-width: 640px) and (min-height: 800px) {
      top: 340px;
			--nav-max-height: calc(100vh - 380px);
    }

		@include breakpoint(medium) {
			right: 90px;
    }

    @include breakpoint(large) {
      top: 215px;
      right: 105px;
			--nav-max-height: calc(100vh - 320px);
    }

    @include breakpoint(xxlarge) {
      right: 125px;
    }

    @media screen and (min-width: 1024px) and (min-height: 1200px) {
			top: 400px;
			--nav-max-height: calc(100vh - 500px);
    }

    .link {
      position: relative;
      display: block;
      margin: 0;

      @include breakpoint(large) {
        font-weight: 700;
      }

			@include breakpoint(large only,medium only){
				font-size: rem-calc(24);
		}

      @include breakpoint(large only) {
        font-size: 34px;
      }

      &:hover {
        color: $gr-or;
      }
    }

  }

  &-ani {
    $hd-ani-sec: 0.48s;

    .hd-collapse {
      opacity: 0;
      pointer-events: none;
      transition: $hd-ani-sec;
    }

    .hd-collapse-bg {
      transform: translateX(50px);
      transition: $hd-ani-sec;
    }

    .hd-social,
    .hd-list {
      opacity: 0;
      transition: $hd-ani-sec opacity $hd-ani-sec;
    }

    .menu {
      &-line {
        transition: $hd-ani-sec;
      }
    }
  }

  &.is-open {
    .hd-collapse {
      opacity: 1;
      pointer-events: all;
    }

    .hd-collapse-bg {
      transform: none;
    }

    .hd-social,
    .hd-list {
      opacity: 1;
    }

    .menu {
      &-line {
        &.top {
          top: 50%;
          transform: translateY(-50%) rotate(-45deg) ;
        }

        &.ctr {
          opacity: 0;
        }

        &.btm {
          top: 50%;
          transform: translateY(-50%) rotate(45deg);
        }
      }

      &::before {
        opacity: 1;
      }
    }
  }

	.bevel-bg .pagetext .img {
		@include breakpoint(large) {
      width: calc(10% + 100vh);
    }
	}

	// Swiper
	.swiper-slide {
		height: auto;
	}

	.swiper-arrow {
		&.prev {
			top: auto;
			right: 0;
			left: auto;
			bottom: calc(100% + 10px);
			transform: rotate(90deg);

			@include breakpoint(small down) {
				bottom: calc(100% + 10px);
			}
		}

		&.next {
			right: 0;
			top: calc(100% + 10px);
			transform: rotate(270deg);

			@include breakpoint(small down) {
				top: calc(100% + 0px);
			}
		}

		&.swiper-button-disabled {
			opacity: 0;
		}
	}
}
// --------------------------------------------------------

/* Footer */
.ft {
  width: 100%;
  background-color: $black;
  position: relative;
  z-index: 999;
  background-image: url('../images/bg-pic_b.jpg');
  background-repeat: repeat;
  background-size: 200px 200px;
  &-topctr {
    padding: rem-calc(30 0 0 0);
  }
  .js-goto-top{
    width: rem-calc(40);
    position: absolute;
    top: rem-calc(-30);
    right: rem-calc(10);
    cursor: pointer;
    transition: 0.4s;
    @include breakpoint(large) {
      &::before{
        content: '';
        width: 100%;
        height: 100%;
        background-size: cover;
        background-image: url('../images/gotop-hover.svg');
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        transition: 0.4s 0.2s;
      }
      img{
        transition: 0.4s;
      }
      &:hover{
        top: rem-calc(-40);
        transition: 0.4s;
        &::before{
          opacity: 1;
          transition: 0.4s;
        }
        img{
          opacity: 0;
          transition: 0.4s 0.2s;
        }
      }
    }
    @include breakpoint(large) {
      width: rem-calc(60);
      top: rem-calc(-30);
      right: rem-calc(40);
    }
  }
  .iconbox {
    display: flex;
    justify-content: center;
    margin-bottom: rem-calc(30);
    &-btn {
      width: rem-calc(45);
      height: rem-calc(45);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $white;
      background: linear-gradient(-45deg,rgba($gr-or,1),rgba($gr-pi,1),rgba($gr-pu,1));
      font-size: rem-calc(40);
      margin: rem-calc(0 10);
      position: relative;
      &::before{
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-color: $white;
        z-index: 1;
        opacity: 1;
        transition: 0.4s;
      }
      .co-icon{
        position: relative;
        z-index: 9;
        color: $black;
        transition: 0.4s;
      }
      @include breakpoint(large) {
        &:hover{
          &::before{
            opacity: 0;
            transition: 0.4s;
          }
          .co-icon{
            color: $white;
            transition: 0.4s;
          }
        }
      }
    }
  }
  .brandbox {
    margin-bottom: rem-calc(5);
    &-title {
      width: 100%;
      color: $white;
      margin-bottom: rem-calc(15);
      font-size: rem-calc(20);
      text-align: center;
      @include breakpoint(large) {
        font-size: rem-calc(24);
        text-align: left;
      }
    }
    &-text {
      @include breakpoint(large) {
        display: flex;
        justify-content: space-between;
      }
      .copyright {
        color: $white;
        font-weight: 300;
        font-size: rem-calc(12);
        @include breakpoint(large) {
          font-size: rem-calc(14);
        }
        @include breakpoint(medium down) {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .otherpage {
        @include breakpoint(medium down) {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &-link {
          color: $white;
          font-weight: 300;
          font-size: rem-calc(12);
          @include breakpoint(large) {
            margin-left: rem-calc(10);
            font-size: rem-calc(14);
          }
          @include breakpoint(medium down) {
            margin: rem-calc(20 5 10 5);
          }
        }
      }
    }
  }
  .designbox {
    display: block;
    padding: rem-calc(12 0);
    border-top: rem-calc(1) solid rgba($white, 0.3);
    text-align: center;
    @include breakpoint(large) {
      text-align: left;
    }
    a,
    p {
      color: rgba($white, 0.6);
      font-weight: 300;
      font-size: rem-calc(14);
      margin: 0;
    }
  }
}
