// --------------------------------------------------------
//
// Font - 字型
//
// --------------------------------------------------------

/* 中文襯線字 */
// 從 HTML 載入 Google Font 設定
.e2,.en {
	font-family: "Exo 2","Noto Sans TC",sans-serif;
	font-optical-sizing: auto;
	font-weight: 700;
	font-style: normal;
}
.ch{
	font-family: "Noto Sans TC","Exo 2", sans-serif;
	font-optical-sizing: auto;
	font-weight: 500;
	font-style: normal;
	letter-spacing: rem-calc(0);
}
.blod{
	font-weight: 600;
}

.text-underline {
  text-decoration: underline;
}