// PageContTitle
.in-title{
	display: inline-flex;
	align-items: flex-end;
	position: relative;
	z-index: 50;
	padding: 10px 0;

	h2{
			position: relative;
			z-index: 1;
			margin: 0;
			@include breakpoint (xlarge only)  {
					font-size: rem-calc(45);
			}
			@include breakpoint(large only,medium only){
					font-size: rem-calc(38);
			}
			@include breakpoint(small down){
					font-size: rem-calc(32);
			}
	}
	.en{
			margin: rem-calc(0 10 0 20);
	}
	&_cube{
			position: absolute;
			background-size: cover;
			&.left{
					width: rem-calc(72);
					height: rem-calc(83);
					transform: rotate(-45deg);
					left: rem-calc(25);
					bottom: rem-calc(60);
					@include breakpoint (xlarge only)  {
							width: rem-calc(57.6);
							height: rem-calc(66.4);
							left: rem-calc(20);
							bottom: rem-calc(55);
					}
					@include breakpoint(large only,medium only){
							width: calc(72px * 0.7);
							height: calc(83px * 0.7);
							left: rem-calc(20);
							bottom: rem-calc(48);
					}
					@include breakpoint(small down){
							width: calc(72px * 0.6);
							height: calc(83px * 0.6);
							left: rem-calc(20);
							bottom: rem-calc(35);
					}
			}
			&.right{
					width: rem-calc(35);
					height: rem-calc(40);
					left: rem-calc(115);
					bottom: rem-calc(95);
					@include breakpoint (xlarge only)  {
							width: rem-calc(28);
							height: rem-calc(32);
							left: rem-calc(95);
							bottom: rem-calc(85);
					}
					@include breakpoint(large only,medium only){
							width: calc(35px * 0.7);
							height: calc(40px * 0.7);
							left: rem-calc(85);
							bottom: rem-calc(80);
					}
					@include breakpoint(small down){
							width: calc(35px * 0.6);
							height: calc(40px * 0.6);
							left: rem-calc(75);
							bottom: rem-calc(60);
					}
			}
	}
	&::before{
			content: '';
			width: rem-calc(80);
			height: 100%;
			position: absolute;
			right: rem-calc(-79);
			bottom: 0;
			background-size: cover;
			@include breakpoint (xlarge only)  {
					right: rem-calc(-55);
			}
			@include breakpoint(large only,medium only){
					width: rem-calc(60);
					right: rem-calc(-40);
			}
			@include breakpoint(small down){
					width: rem-calc(48);
					right: rem-calc(-30);
			}
	}
	&.black{
			background-color: $black;
			color: $white;
			&::before{
					background-image: url('../images/index/titlebg.svg');
			}
			.in-title_cube{
					background-image: url('../images/index/titlecube.svg');
			}
	}
	&.white{
			background-color: $white;
			color: $black;
			&::before{
					background-image: url('../images/index/titlebg-w.svg');
			}
			.in-title_cube{
					background-image: url('../images/index/titlecube-w.svg');
			}
	}
	&.ctr{
			margin: 0 auto;
			transform: translateX(rem-calc(-45));
			@include breakpoint (xlarge only)  {
					transform: translateX(rem-calc(-28));
			}
			@include breakpoint (large only,medium only)  {
					transform: translateX(rem-calc(-18));
			}
			@include breakpoint(small down){
					transform: translateX(rem-calc(-18));
			}
	}
	&:not(.pagetitle){
		.ch{
			font-size: rem-calc(12);
			transform: translateY(rem-calc(-15));
			@include breakpoint(xlarge){
					font-size: rem-calc(14);
			}
			@include breakpoint(large only,medium only){
					transform: translateY(rem-calc(-9));
			}
			@include breakpoint(small down){
					transform: translateY(rem-calc(-9));
			}
		}
	}
	&.pagetitle{
		width: max-content;
		min-width: rem-calc(180);
		height: rem-calc(60);
		background-color: $black;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto rem-calc(40) auto;
		@include breakpoint(small down){
			max-width: rem-calc(200);
			height: rem-calc(45);
		}
		.in-title_cube{
			opacity: 0;
		}
		.ch{
			position: relative;
			padding: 10px 40px;
			background-color: $black;
			color: $white;
			font-weight: bold;
			margin: 0;
		}
		&::after{
			content: '';
			width: rem-calc(80);
			height: 100%;
			position: absolute;
			left: rem-calc(-79);
			bottom: 0;
			background-size: cover;
			background-image: url('../images/index/titlebg.svg');
			transform: rotate(-180deg);
			@include breakpoint (xlarge only)  {
				left: rem-calc(-55);
			}
			@include breakpoint(large only,medium only){
				width: rem-calc(60);
				left: rem-calc(-40);
			}
			@include breakpoint(small down){
				width: rem-calc(30);
				left: rem-calc(-30);
			}
		}
	}
}

.pcsubtitle{
		width: 100%;
		margin: rem-calc(20 0 0 0);
		@include breakpoint (xlarge only)  {
				font-size: rem-calc(20);
		}
		@include breakpoint (large down,medium only)  {
				margin: rem-calc(10 0 0 0);
				font-size: rem-calc(16);
		}
}

.block-title {
	margin-bottom: 40px;
	display: flex;
	align-items: center;
	justify-content: center;

	&_title {
		position: relative;
		padding: 10px 40px;
		background-color: $black;
		color: $white;
		font-weight: bold;
	}

	&_deco {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		transform: translate(-50%, -50%);
		width: 50px;
		height: 50px;
	}
}