// 文章相關

.newscard{
    .ncbox{
        position: relative;
        opacity: 0;
        transition: 0.2s;
        overflow: hidden;
        &.swiper-slide-active{
            opacity: 1;
            transition: 0.4s 0.2s;
        }
        &::before{
            content: '';
            width: calc(100% + 2px);
            height: rem-calc(120);
            position: absolute;
            top: rem-calc(-1);
            left: rem-calc(-1);
            // background-color: rgba($white,1);
            z-index: 2;
            @include breakpoint (xlarge only)  {
                height: rem-calc(90);
            }
            @include breakpoint(large only,medium only){
                height: rem-calc(80);
            }
            @include breakpoint(small down){
                height: rem-calc(70);
            }
        }
        img{
            width: 100%;
        }
        &-toppic,&-btmpic,&-text{
            width: 100%;
            transition: 0.2s;
        }
        &-toppic{
            position: absolute;
            left: 0;
            top: 0;
            z-index: 3;
        }
        &-btmpic{
            height: 54%;
            margin-top: rem-calc(122);
            margin-bottom: rem-calc(-122);
            overflow: hidden;
            position: relative;
            z-index: 1;
            opacity: 0.4;
            -webkit-filter: grayscale(100%); /* Safari */
            filter: grayscale(100%);
            @include breakpoint (xlarge only)  {
                height: 50%;
                margin-top: rem-calc(95);
                margin-bottom: rem-calc(-95);
            }
            @include breakpoint (large only)  {
                height: 45%;
                margin-top: rem-calc(98);
                margin-bottom: rem-calc(-98);
            }
            @include breakpoint (medium only)  {
                height: 45%;
                margin-top: rem-calc(98);
                margin-bottom: rem-calc(-98);
            }
            @include breakpoint (small only)  {
                height: 50%;
                margin-top: rem-calc(48);
                margin-bottom: rem-calc(-48);
            }
        }
        &-text{
            background: linear-gradient(to right,rgba($gr-or,1),rgba($gr-pi,1),rgba($gr-pu,1));
            padding: rem-calc(10 15);
            position: relative;
            z-index: 5;
            .ch{
                position: relative;
                z-index: 3;
                line-height: 200%;
                overflow: hidden;
                display: -webkit-box;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                -moz-line-clamp: 1;
                -ms-line-clamp: 1;
                -webkit-box-orient: vertical;
                margin: 0;
                transition: 0.6s;
            }
            &::before{
                content: '';
                width: 100%;
                height: 100%;
                background-color: rgba($white,1);
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
                opacity: 1;
                transition: 0.4s;
            }
            @include breakpoint(large down){
                font-size: rem-calc(14);
                padding: rem-calc(6 10);
            }
        }
        @include breakpoint (large) {
            &:hover{
                .ncbox-toppic{
                    transform: translateX(rem-calc(50));
                    -webkit-filter: brightness(120%); /* Safari */
                    filter: brightness(120%);
                    transition: 0.6s;
                }
                .ncbox-btmpic{
                    -webkit-filter: grayscale(0%); /* Safari */
                    filter: grayscale(0%);
                    opacity: 1;
                    transition: 0.6s;
                }
                .ncbox-text{
                    .ch{
                        color: rgba($white,1);
                        transition: 0.6s;
                    }
                    &::before{
                        opacity: 0;
                        transition: 0.6s;
                    }
                }
            }
        }
        @include breakpoint(medium down){
            .ncbox-toppic{
                transform: translateX(rem-calc(0));
                -webkit-filter: brightness(100%); /* Safari */
                filter: brightness(100%);
                transition: 0.6s;
            }
            .ncbox-btmpic{
                -webkit-filter: grayscale(0%); /* Safari */
                filter: grayscale(0%);
                opacity: 1;
                transition: 0.6s;
            }
            .ncbox-text{
                .ch{
                    color: rgba($white,1);
                    transition: 0.6s;
                }
                &::before{
                    opacity: 0;
                    transition: 0.6s;
                }
            }
        }
    }
    .swiper-arrow{
        top: calc(50% - 40px);
        &.prev{
            left: rem-calc(-30);
            @include breakpoint (xlarge down)  {
                left: rem-calc(-20);
            }
        }
        &.next{
            right: rem-calc(-30);
            transform: rotate(180deg);
            @include breakpoint (xlarge down)  {
                right: rem-calc(-20);
            }
        }
    }
    .swiper-pagination{
        bottom: initial !important;
    }
}
.ncscard{
    display: block;
    position: relative;
    padding: rem-calc(12 20 12 70);
    border-bottom: rem-calc(1) solid $black;
    @include breakpoint (xlarge only)  {
        padding: rem-calc(8 20 8 60);
    }
    @include breakpoint(large only){
        padding: rem-calc(5 10 5 45);
    }
    @include breakpoint(medium only){
        padding: rem-calc(2 30 8 30);
    }
    @include breakpoint(small only){
        padding: rem-calc(2 0 8 0);
    }
    &::before{
        content: '';
        width: 100%;
        height: 100%;
        background: linear-gradient(to right,rgba($gr-or,1),rgba($gr-pi,1),rgba($gr-pu,1));
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        opacity: 0;
        transition: 0.2s;
    }
    &.is-new{
        .ncscard-newtag{
            display: table;
        }
    }
    &-title,&-time,&-btn{
        color: $black;
        position: relative;
        z-index: 2;
        transition: 0.2s;
        
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -moz-line-clamp: 2;
        -ms-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    &-newtag{
        color: $white;
        font-size: rem-calc(12);
        font-weight: 400;
        background-color: $black;
        padding: rem-calc(0 5 1 5);
        display: none;
        transition: 0.2s;
        @include breakpoint(large){
            position: absolute;
            left: rem-calc(20);
            top: rem-calc(12);
            z-index: 2;
        }
        @include breakpoint (xlarge only)  {
            left: rem-calc(10);
        }
        @include breakpoint(large only){
            top: rem-calc(5);
            left: rem-calc(3);
            transform: scale(0.85);
        }
    }
    &-title{
        @include breakpoint(large down){
            font-size: rem-calc(14);
        }
        @include breakpoint(medium down){
            margin-top: rem-calc(8);
        }
    }
    &-time{
        font-size: rem-calc(14);
        font-weight: 600;
        opacity: 0.6;
        @include breakpoint (xlarge down)  {
            font-size: rem-calc(12);
        }
    }
    &-btn{
        color: $black;
        position: absolute;
        right: rem-calc(20);
        bottom: rem-calc(12);
        &::before{
            content: '';
            width: 100%;
            max-width: rem-calc(0);
            height: rem-calc(2);
            background-color: rgba($white,1);
            position: absolute;
            left: 0;
            top: calc(50% - 0px);
            opacity: 0;
            padding: rem-calc(0 3);
        }
        @include breakpoint (xlarge down)  {
            font-size: rem-calc(14);
            bottom: rem-calc(8);
        }
        @include breakpoint(large only){
            bottom: rem-calc(6);
        }
        @include breakpoint(medium down){
            right: rem-calc(0);
        }
    }
    @include breakpoint (large) {
        &:hover{
            &::before{
                opacity: 1;
                transition: 0.6s;
            }
            .ncscard-newtag{
                color: $black;
                background-color: $white;
                transition: 0.6s;
            }
            .ncscard-title,.ncscard-time,.ncscard-btn{
                color: $white;
                transition: 0.6s;
            }
            .ncscard-btn{
                transform: scale(1.2) translateX(rem-calc(-10));
                &::before{
                    opacity: 1;
                    max-width: rem-calc(100);
                    transition: 0.6s 0.4s;
                }
                @include breakpoint(large only){
                    transform: scale(1.1) translateX(rem-calc(-10));
                }
            }
        }
    }
}